import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout-1/layout-1.component';
import { Layout1FlexComponent } from './layout-1-flex/layout-1-flex.component';
import { Layout2Component } from './layout-2/layout-2.component';
import { Layout2FlexComponent } from './layout-2-flex/layout-2-flex.component';
import { LayoutWithoutNavbarComponent } from './layout-without-navbar/layout-without-navbar.component';
import { LayoutWithoutNavbarFlexComponent } from './layout-without-navbar-flex/layout-without-navbar-flex.component';
import { LayoutWithoutSidenavComponent } from './layout-without-sidenav/layout-without-sidenav.component';
import { LayoutHorizontalSidenavComponent } from './layout-horizontal-sidenav/layout-horizontal-sidenav.component';
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';

// *******************************************************************************
// Components

import { LayoutNavbarComponent } from './layout-navbar/layout-navbar.component';
import { LayoutSidenavComponent } from './layout-sidenav/layout-sidenav.component';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';

// *******************************************************************************
// Libs

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidenavModule } from '../../vendor/libs/sidenav/sidenav.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SafePipeModule } from '../../components/safe-pipe/safePipe.module';
import { CircleTimerModule } from '../../components/circle-timer/circle-timer.module';

// *******************************************************************************
// Services

import { LayoutService } from './layout.service';

// *******************************************************************************
// Pipes

import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';


import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    imports: [
        AbilityModule,
        CommonModule,
        RouterModule,
        NgbModule,
        SidenavModule,
        FormsModule,
        PerfectScrollbarModule,
        SafePipeModule,
        CircleTimerModule,
        AngularSvgIconModule,
    ],
    declarations: [
        Layout1Component,
        Layout1FlexComponent,
        Layout2Component,
        Layout2FlexComponent,
        LayoutWithoutNavbarComponent,
        LayoutWithoutNavbarFlexComponent,
        LayoutWithoutSidenavComponent,
        LayoutHorizontalSidenavComponent,
        LayoutBlankComponent,

        LayoutNavbarComponent,
        LayoutSidenavComponent,
        LayoutFooterComponent,
    ],
    providers: [
        LayoutService,
        { provide: Ability, useValue: new Ability() },
        { provide: PureAbility, useExisting: Ability },
    ],
})
export class LayoutModule {}
