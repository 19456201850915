/* ----------------------------- Angular Imports ---------------------------- */
import { Injectable, Injector } from '@angular/core';

/* ----------------------------- Service Imports ---------------------------- */
import { BaseService } from './base.service';

/* ------------------------------ Type Imports ------------------------------ */
import type { UserGroupFE as UserGroup } from 'server/api/userGroup/userGroup';
export type { UserGroupFE as UserGroup } from 'server/api/userGroup/userGroup';

@Injectable()
export class UserGroupService extends BaseService<UserGroup> {
    modelName = 'userGroups';
    constructor(injector: Injector) {
        super(injector);
    }
}
