<sidenav [orientation]="orientation" [ngClass]="getClasses()" class="no-print">

  <!-- Brand demo (see src/demo.css) -->
  <div class="app-brand demo" *ngIf="orientation !== 'horizontal'">
    <span routerLink="/" class="app-brand-logo demo">
        <div *ngIf="!(hasAccountImage | async)" style="width:100%;height:100%;">
            <svg-icon [src]="logoService.domainLogo" [class]="'logo'" [svgStyle]="{'width': '100%', 'height': '100%'}">
                NoKey Logo <!-- fallback image in CSS -->
            </svg-icon>
            <svg-icon [src]="logoService.domainIcon" [class]="'icon'" [svgStyle]="{'width': '100%', 'height': '100%'}">
                NoKey Icon <!-- fallback image in CSS -->
            </svg-icon>

        </div>
        <div *ngIf="hasAccountImage | async">
            <img [src]="accountImage" alt  style="width:100%;padding:0.5em">
        </div>
    </span>
    <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" (click)="toggleSidenav()">
      <i class="ion ion-md-menu align-middle"></i>
    </a>
  </div>
  <div class="sidenav-divider mt-0" *ngIf="orientation !== 'horizontal'"></div>

  <!-- Inner -->
  <div class="sidenav-inner" [ngClass]="{ 'py-1': orientation !== 'horizontal' }">

    <sidenav-router-link *ngIf="'view' | able:'Dashboards'" icon="fas fa-laptop-code" route="/accPlaceholder/dashboard" [active]="isActive('/dashboard')">Dashboard</sidenav-router-link>
	<!-- Sites -->
    <sidenav-router-link *ngIf="'view' | able:'Sites'" icon="fas fa-door-closed" route="/accPlaceholder/sites/list" [active]="isActive('/sites')">Sites</sidenav-router-link>

    <!-- Controllers -->
    <sidenav-router-link *ngIf="'view' | able:'Controllers'" icon="fas fa-laptop-code" route="/accPlaceholder/units/list" [active]="isActive('/units')">Controllers</sidenav-router-link>

    <!-- Access Control -->
    <sidenav-menu *ngIf="('view' | able:'Access Control') || ('view' | able:'Schedules')" icon="fas fa-address-card" text="Access Control" [active]="isMenuActive('/rules')" [open]="isMenuOpen('/rules')">
        <sidenav-router-link *ngIf="'view' | able:'Access Control'" icon="fas fa-list" route="/accPlaceholder/rules/list" [active]="isActive('/rules/list')">Manage Access</sidenav-router-link>
        <sidenav-router-link *ngIf="'view' | able:'Schedules'" icon="far fa-calendar-alt" route="/accPlaceholder/rules/schedules" [active]="isActive('/rules/schedules')">Manage Schedules</sidenav-router-link>
        <sidenav-router-link *ngIf="'view' | able:'Access Control'" icon="far fa-user-unlock" route="/accPlaceholder/rules/adhoc" [active]="isActive('/rules/adhoc')">Adhoc Access</sidenav-router-link>
    </sidenav-menu>

    <!-- Access Events -->
    <sidenav-router-link *ngIf="'view' | able:'Access Events'" icon="fas fa-fingerprint" route="/accPlaceholder/events/list" [active]="isActive('/events')">Access Events</sidenav-router-link>
    
    <!-- Incident Reports -->
    <sidenav-router-link *ngIf="'view' | able:'Incident Reports'" icon="fas fa-file-chart-column" route="/accPlaceholder/incidentReports/list" [active]="isActive('/incidentReports')">Incident Reports</sidenav-router-link>

    <!-- Contacts -->
    <sidenav-router-link *ngIf="'view' | able:'Contacts'" icon="fas fa-users" route="/accPlaceholder/contacts/list" [active]="isActive('/contacts')">Contacts</sidenav-router-link>

    <!-- Users -->
    <sidenav-menu *ngIf="'view' | able:'Users'" icon="fas fa-user-friends" text="Users" [active]="isMenuActive('/users')" [open]="isMenuOpen('/users')">
      <sidenav-router-link icon="fas fa-list" route="/accPlaceholder/users/list" [active]="isActive('/users/list')">List</sidenav-router-link>
      <sidenav-router-link icon="fas fa-key" route="/accPlaceholder/users/settings" [active]="isActive('/users/settings')">Password Reset / MFA</sidenav-router-link>
    </sidenav-menu>

    <!-- Account -->
      <sidenav-router-link *ngIf="'view' | able:'Accounts'" icon="fas fa-cogs" route="/accPlaceholder/account/list" [active]="isActive('/account/list')">Accounts</sidenav-router-link>

    <!-- Tenant -->
      <sidenav-router-link *ngIf="'view' | able:'Tenants'" icon="fas fa-users-medical" route="/accPlaceholder/tenant/list" [active]="isActive('/tenant/list')">Identity Providers</sidenav-router-link>

    <!-- Recertify -->
      <sidenav-router-link *ngIf="'recertify' | able:'Users'" icon="fas fa-users-medical" route="/accPlaceholder/recertify/list" [active]="isActive('/recertify/list')">Recertify Users</sidenav-router-link>
  </div>
</sidenav>
