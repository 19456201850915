/* ----------------------------- Angular Imports ---------------------------- */
import { Component, Input, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

/* ----------------------------- Module Imports ----------------------------- */
import { Ability } from '@casl/ability';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { remove } from 'lodash';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

/* ----------------------------- Service Imports ---------------------------- */
import { AccountService } from 'client/services/account.service';
import { AppService } from '../../app.service';
import { AuthService } from '../../../components/auth/auth.service';
import { LayoutService } from '../../layout/layout.service';
import { TaskService, Task } from '../../../services/task.service';

/* ------------------------------ Type Imports ------------------------------ */
import type { HierarchicalAccount } from 'client/services/account.service';
import type { BaseQuery } from '../../../services/base.service';
import type { SyncEvent } from '../../../components/socket/socket.service';

interface Account {
    name: string;
    ref: string;
}

@Component({
    selector: 'app-layout-navbar',
    templateUrl: './layout-navbar.component.html',
    styleUrls: ['../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss'],
})
export class LayoutNavbarComponent implements OnInit {
    /* -------------------------------------------------------------------------- */
    /*                                 Properties                                 */
    /* -------------------------------------------------------------------------- */
    isExpanded = false;
    isRTL: boolean;
    user;

    @ViewChild('notificationsDropdown', { static: true })
    notificationsDropdown: NgbDropdown;

    @Input()
    sidenavToggle = true;
    searchAccount = '';
    currentAccount: Account;
    activeTasks: Task[] = [];
    subscription: Subscription;

    /** The account hierarchy for the current user. */
    private hierarchy: HierarchicalAccount[] = [];
    /** The filtered account hierarchy for the current user. */
    protected filteredHierarchy: HierarchicalAccount[] = [];

    @HostBinding('class.layout-navbar') hostClassMain = true;

    /* -------------------------------------------------------------------------- */
    /*                                 Constructor                                */
    /* -------------------------------------------------------------------------- */
    constructor(
        private accountService: AccountService,
        private appService: AppService,
        private layoutService: LayoutService,
        public authService: AuthService,
        private router: Router,
        private ability: Ability,
        private taskService: TaskService,
        private toastrService: ToastrService
    ) {
        this.isRTL = appService.isRTL;
        this.authService = authService;
        this.ability.update(this.authService.abilityRules);
    }

    /* -------------------------------------------------------------------------- */
    /*                               Init Functions                               */
    /* -------------------------------------------------------------------------- */
    ngOnInit(): void {
        this.accountService
            .getHierarchy()
            .then((res) => {
                this.hierarchy = res;
                this.filterAccounts();
            })
            .catch(console.error);
        this.user = this.authService.getCurrentUserSync();
        this.currentAccount = this.authService.currentAccount;
        if (this.ability.can('manage', 'RemoteAuth')) {
            this.updateData();

            this.subscription = this.taskService
                .register(
                    this.activeTasks,
                    {
                        addCreated: false,
                    },
                    `task:${this.authService.currentAccount.ref}:*`
                )
                .subscribe(this.handleTask.bind(this));
        }
    }

    ngOnDestroy() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    updateData() {
        const query: BaseQuery = {
            by: 'createdAt',
            order: 'desc',
            params: [
                {
                    field: 'type',
                    value: 'RemoteAuthRequest',
                    type: 'string',
                },
                {
                    field: 'status',
                    value: 'Requested',
                    type: 'string',
                },
            ],
        };
        this.taskService.getList(query).subscribe(
            (tasks) => {
                this.activeTasks.length = 0;
                tasks.forEach((a) => this.activeTasks.push(a));
            },
            (err) => console.error(err)
        );
    }

    /**
     * Filters the accounts based on the search term.
     */
    protected filterAccounts() {
        if (!this.searchAccount.length) {
            this.filteredHierarchy = this.hierarchy;
            this.sortHierarchy();
            return;
        }
        const filterFunc = (account: HierarchicalAccount) => {
            const matchingChildren = account.children.filter(filterFunc);
            account.children = matchingChildren;
            if (account.name.toLowerCase().includes(this.searchAccount.toLowerCase())) return true;
            if (account.children.length) return true;
            return false;
        };
        this.filteredHierarchy = structuredClone(this.hierarchy).filter(filterFunc);
        this.sortHierarchy();
    }

    /**
     * Sorts the hierarchy by name.
     */
    private sortHierarchy() {
        const sortAccs = (accounts: HierarchicalAccount[]) => {
            for (const account of accounts) {
                if (account.children?.length) sortAccs(account.children);
            }
            accounts.sort((a, b) => a.name.localeCompare(b.name));
        };
        sortAccs(this.filteredHierarchy);
    }

    /* -------------------------------------------------------------------------- */
    /*                            Button Click Handlers                           */
    /* -------------------------------------------------------------------------- */
    currentBg(): string {
        return `bg-${this.appService.layoutNavbarBg}`;
    }

    toggleSidenav(): void {
        this.layoutService.toggleCollapsed();
    }

    /**
     * Changes the current account to the selected account.
     *
     * @param account The account to set as the current account.
     */
    protected setCurrentAccount(account: HierarchicalAccount) {
        const newUrl = this.router.url.replace(this.currentAccount.ref, account._id);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigateByUrl(newUrl).catch(console.error);
    }

    handleTask(event: SyncEvent) {
        const newTask = event.item as Task;
        //The default filter sort is selected.  Add event to start of array.
        if (newTask.type === 'RemoteAuthRequest') {
            if (event.event == 'create') {
                const activeToast = this.toastrService.info('New Access Requested');
                activeToast.onTap.subscribe(() => {
                    this.notificationsDropdown.open();
                });
                this.activeTasks.unshift(newTask);
            } else if (event.event === 'update') {
                if (newTask.status !== 'Requested') {
                    remove(this.activeTasks, ['_id', newTask._id]);
                }
            }
        }
    }

    clearTask(task: Task) {
        remove(this.activeTasks, ['_id', task._id]);
    }

    grantAccess(taskId: string) {
        this.taskService.actionTask(taskId, true).subscribe(
            () => {
                this.toastrService.info('Access granted');
            },
            (err) => {
                console.error(err);
                this.toastrService.error('Access grant failed');
            }
        );
    }

    denyAccess(taskId: string) {
        this.taskService.actionTask(taskId, false).subscribe(
            () => {
                this.toastrService.info('Access denied');
            },
            (err) => {
                console.error(err);
                this.toastrService.error('Access deny failed');
            }
        );
    }
}
