// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService } from './base.service';

import type { MetaDataTemplateFE as MetaDataTemplate } from 'server/api/metaDataTemplate/metaDataTemplate';
export type { MetaDataTemplateFE as MetaDataTemplate } from 'server/api/metaDataTemplate/metaDataTemplate';

@Injectable()
export class MetaDataTemplateService extends BaseService<MetaDataTemplate> {
    modelName = 'metaDataTemplates';
    constructor(injector: Injector) {
        super(injector);
    }
}
