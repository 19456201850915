import { Injectable, Injector } from '@angular/core';
//import { SocketService } from '../components/socket/socket.service';
//import { HttpClient } from '@angular/common/http';
import type { ContactRef } from './contact.service';

import { lastValueFrom, Observable } from 'rxjs';
import { BaseService } from './base.service';
import type { AccountFrontend as Account } from 'server/api/account/account';
export type {
    AccountFrontend as Account,
    AccountStubFrontend as AccountStub,
    ExpandedStubFE as ExpandedStub,
} from 'server/api/account/account';

export interface HierarchicalAccount extends Account {
    children?: HierarchicalAccount[];
}

@Injectable()
export class AccountService extends BaseService<Account> {
    modelName = 'accounts';
    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Get the account hierarchy for the current user.
     *
     * @returns A promise that resolves to the account hierarchy for the user.
     */
    public getHierarchy() {
        return lastValueFrom(this.http.get<HierarchicalAccount[]>('/api/accounts/hierarchy'));
    }

    enforceMFA(accountId: string, enable: boolean): Observable<any> {
        return this.http.post<any>(`/api/accounts/${accountId}/enforceMFA`, {
            enforce: enable,
        });
    }
}
