import {
    Component,
    Input,
    AfterViewInit,
    HostBinding,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { LayoutService } from '../layout.service';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../../../components/auth/auth.service';
import { LogoService } from '../../../components/logoManager/logoManager.service';
import {
    defineAbilityForAccount,
    Actions,
    Subjects,
} from '../../../components/auth/ability-builder';

import { Ability } from '@casl/ability';
interface Account {
    name?: string;
    ref?: string;
}

@Component({
    selector: 'app-layout-sidenav',
    templateUrl: './layout-sidenav.component.html',
    styles: [':host { display: block; }'],
})
export class LayoutSidenavComponent implements AfterViewInit, OnInit {
    currentAccountId: string;
    currentAccount: Account;
    accountImage: string;
    hasAccountImage: Observable<boolean>;
    @Input() orientation = 'vertical';

    @HostBinding('class.layout-sidenav') hostClassVertical = false;
    @HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false;
    @HostBinding('class.flex-grow-0') hostClassFlex = false;

    constructor(
        private router: Router,
        private appService: AppService,
        private layoutService: LayoutService,
        private ability: Ability,
        private authService: AuthService,
        private http: HttpClient,
        public logoService: LogoService
    ) {
        // Set host classes
        this.ability.update(this.authService.abilityRules);
        this.hostClassVertical = this.orientation !== 'horizontal';
        this.hostClassHorizontal = !this.hostClassVertical;
        this.hostClassFlex = this.hostClassHorizontal;
    }

    ngOnInit() {
        this.currentAccount = this.authService.currentAccount;
        this.hasAccountImage = this.checkAccountImage();
    }

    checkAccountImage(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.http
                .get(`api/accounts/${this.currentAccount.ref}/image`, {
                    responseType: 'blob',
                })
                .subscribe(
                    (res) => {
                        res.arrayBuffer().then((buff) => {
                            this.accountImage = String.fromCharCode.apply(
                                null,
                                new Uint8Array(buff)
                            );
                        });
                        observer.next(true);
                    },
                    (err) => {
                        observer.next(false);
                    },
                    () => observer.complete()
                );
        });
    }

    ngAfterViewInit() {
        // Safari bugfix
        this.layoutService._redrawLayoutSidenav();
        this.currentAccountId = this.authService.currentAccount.ref;
    }

    getClasses() {
        let bg = this.appService.layoutSidenavBg;

        if (
            this.orientation === 'horizontal' &&
            (bg.includes(' sidenav-dark') || bg.includes(' sidenav-light'))
        ) {
            bg = bg
                .replace(' sidenav-dark', '')
                .replace(' sidenav-light', '')
                .replace('-darker', '')
                .replace('-dark', '');
        }

        return `${
            this.orientation === 'horizontal' ? 'container-p-x ' : ''
        } bg-${bg}`;
    }

    isActive(url) {
        return this.router.url.includes(url);
    }

    isMenuActive(url) {
        return this.router.url.includes(url);
    }

    isMenuOpen(url) {
        return (
            this.router.url.includes(url) && this.orientation !== 'horizontal'
        );
    }

    toggleSidenav() {
        this.layoutService.toggleCollapsed();
    }
}
