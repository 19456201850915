<h1>END USER LICENSE AGREEMENT</h1>
<p>Last updated: March 14, 2025</p>
<p><strong>ATTENTION: PLEASE READ THIS DOCUMENT BEFORE INSTALLING OR USING THE LICENSED SOFTWARE.</strong></p>

<p>This is a license agreement ("Agreement") between you (either an individual or an entity) and Secutel Technologies Pty Ltd., having its place of business at 5 Upper Lake Lan, Constantia Nek, Roodepoort, Gauteng (“Secutel”), with respect to (i) software programs provided to you with this Agreement, its associated electronic or online manuals, and updates thereof, if any (collectively, the "Software"), (ii) additional data for the Software or Secutel’s NoKey Platform and Devices ("NoKey") or updates to firmware for the Devices or Platform, provided online using the Software ("Data Updates"), and (iii) text, images, graphics and other visual representations in digital format (excluding the Software) for your access control use, provided with this Agreement or provided online using the Software, and updates thereof, if any ("Contents Data"). Software, Data Updates and Contents Data shall hereinafter be referred to individually or collectively as the "Licensed Software".</p>

<p>Notwithstanding anything contained herein to the contrary, Licensed Software also includes certain software modules and libraries to which the terms of this Agreement do not apply ("Third Party Software"). The list of Third Party Software and the terms of use applicable thereto may be indicated at the end of this Agreement, relevant documentation such as user manual, or the license information displayed in the Licensed Software.</p>

<p>BY CLICKING THE BUTTON PROVIDED WITH THIS AGREEMENT INDICATING YOUR ACCEPTANCE OF THIS AGREEMENT, INSTALLING THE LICENSED SOFTWARE, OR OTHERWISE USING THE LICENSED SOFTWARE YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT CLICK THE FOREGOING BUTTON AND PROMPTLY CLOSE THE INSTALLATION PROGRAM. THE LICENSED SOFTWARE WILL THEN NOT BE INSTALLED AND YOU WILL HAVE NO RIGHT TO USE IT. You agree to use the Licensed Software only in accordance with the terms set forth below.</p>

<h2>1. Ownership and Copyright:</h2>
<p>All right, title and interest in the Licensed Software is owned by Secutel. Secutel shall at all times retain all copyright and other intellectual property rights in the Licensed Software and all subsequent copies thereof regardless of form, including the Print (as defined below). Except as expressly provided herein, no license or right, express or implied, is hereby conveyed or granted by Secutel to you for any intellectual property of Secutel. You shall not modify, remove or delete any copyright notice of Secutel contained in the Licensed Software and all subsequent copies thereof regardless of form.</p>

<h2>2. License:</h2>
<p>Your following license to use the Licensed Software is non-exclusive and non-transferable:</p>
<ol>
<li>(1) You are permitted to use the Software ("use" shall mean installing, executing or displaying the Software) on your multiple computers or access devices. You shall not rent, lease, sublicense, loan, sell, assign, convey, and transfer the Software.</li>
<li>(2) You are permitted to use the Data Updates ("use" shall mean installing, executing or displaying the Data Updates) on your multiple computers or NoKey Contraollers. You shall not rent, lease, sublicense, loan, sell, assign, convey, and transfer the Data Updates.</li>
<li>(3) You are permitted to copy and use the Contents Data ("use" shall mean downloading via the internet using the Software, installing, executing or displaying the Contents Data) on your multiple computers for your own personal use. You shall not rent, lease, sublicense, loan, sell, assign, convey nor transfer the Contents Data. You are also permitted to print the Contents Data and to use, have used, copy, have copied and distribute such prints ("Print") for your own non-commercial purposes only. You shall not use, have used, copy, have copied nor distribute the Print for commercial purposes. Secutel shall not be liable for your use of the access control, nor any claim and suit against you arising from or in any way related to the use of the NoKey access control, including any liability or expense arising from all claims, losses, damages, suits, judgments, litigation costs and attorneys' fees.</li>
<li>(4) You are permitted to make a reasonable number of back-up copies of the Licensed Software in support of the permitted use, provided that all such copies include the Secutel copyright notices as they appear in the original copy of the Licensed Software provided to you.</li>
<li>(5) You shall not use or copy the Licensed Software for the purpose which is offensive to public order and morals or is relating to antisocial forces, and shall not permit any third party to do so or have any third party do so.</li>
<li>(6) You shall not use or copy the Contents Data for any purpose relating to adult contents or any antisocial organizations, and shall not permit any third party to do so or have any third party do so.</li>
<li>(7) You shall not use or copy the Contents Data in any manner which infringes or misappropriates any copyright, portrait right, trademark or any other rights, regarding the Contents Data, of Secutel, its subsidiaries and licensors of any of them, and shall not permit any third party to do so or have any third party do so.</li>
<li>(8) You shall not use or register any part of the Contents Data for you or any third party as trademarks, trade names, service marks or any other trade descriptions, and shall not permit or have any third party do so.</li>
<li>(9) You shall not use or copy the Contents Data for the purpose which causes damage to the image of Secutel, its subsidiaries and licensors of any of them, and shall not permit any third party to do so or have any third party do so.</li>
</ol>

<p>Except as expressly provided herein, you shall not rent, lease, sub-license, loan, sell, assign, convey, transfer, copy, reproduce, modify, adapt, merge, translate, convert to another programming language, reverse-engineer, decompile, alter, disassemble or create derivative works based in whole or in part on the Licensed Software, nor permit any third party to do so, or to use the Licensed Software, except as expressly permitted by this Agreement and except to the extent and in the circumstances expressly permitted by law.</p>
<h2>3. Acknowledgement of Transmission of Information:</h2>
<p>Some Software may transmit information about the NoKey system you use, the Software and your device when you access servers of Secutel or any third party designated by Secutel via the internet using the Software. You expressly acknowledge and agree that Secutel may (i) use such information in order to provide you with information and data suitable for your access control environment and (ii) analyze and use such information in order to develop products and to improve the quality of products and services. If such information includes your personal information, Secutel will obtain your consent, where required by applicable laws in your country or region and use your personal information according to Secutel's privacy policy.</p>

<h2>4. Export Restriction:</h2>
<p>You agree to comply with all export laws and restrictions and regulations of each country involved, as well as with the U.S. Export Administration Regulations ("EAR"), and not to export or re-export, directly or indirectly, the Licensed Software in violation of such laws, restrictions and regulations, or without all necessary approvals.</p>

<h2>5. Termination:</h2>
<p>This Agreement is effective when you click the button provided with this Agreement indicating your acceptance of this Agreement, install the Licensed Software, or otherwise use the Licensed Software and remains in effect until terminated. You may terminate this Agreement by destroying the Licensed Software including any and all copies (excluding the Data Updates installed on the Computers or Phones). This Agreement may also be terminated if you fail to comply with any of its terms and conditions. Upon termination for any reason whatsoever, you shall immediately destroy all copies of the Licensed Software including any Licensed Software stored on the hard disk or solid state drive of any computer in your possession, power or control, but excluding the Data Updates installed on the NoKey Controllers. In addition, Secutel is entitled to exercise any contractual or legal rights, powers or remedies it may have. Notwithstanding the foregoing, Sections 1, 3, 5 through 9 hereof shall survive any termination hereof.</p>

<h2>6. Support and Update:</h2>
<p>Secutel and Secutel's Licensors, are not responsible for maintaining or assisting your use of the Licensed Software. Secutel and its subsidiaries and affiliates, and their respective distributors and dealers and Secutel's Licensors, are not obligated to provide any updates, fixes or support to the Licensed Software unless otherwise expressly agreed in writing between you and any of the above entities.</p>

<h2>7. LIMITED WARRANTY:</h2>
<p>THE LICENSED SOFTWARE IS LICENSED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES AS TO MERCHANTABILITY, QUALITY AND/OR FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF ANY RIGHT, TITLE AND INTEREST, INCLUDING WITHOUT LIMITATION, INTELLECTUAL PROPERTY.</p>

<p>THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE LICENSED SOFTWARE IS WITH YOU. SHOULD THE LICENSED SOFTWARE PROVE DEFECTIVE, YOU (AND NOT SECUTEL, AND SECUTEL'S LICENSORS) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION.</p>

<p>SOME STATES OR LEGAL JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR CONDITIONS, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE OR JURISDICTION TO JURISDICTION.</p>

<p>Secutel DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE LICENSED SOFTWARE WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE LICENSED SOFTWARE WILL BE UNINTERRUPTED OR ERROR FREE. However, if the Licensed Software is provided on a medium, such as a CD-ROM, Secutel warrants the medium on which the Licensed Software is stored to be free from defects in materials and workmanship under normal use for a period of ninety (90) days from the date when you obtained the same as evidenced by a receipt or by other means acceptable to Secutel ("LIMITED WARRANTY").</p>

<h2>8. DISCLAIMER OF WARRANTIES:</h2>
<p>THE LIMITED WARRANTY THAT APPEARS ABOVE IS THE ONLY WARRANTY MADE TO YOU AND IT IS PROVIDED IN LIEU OF ALL WARRANTIES, CONDITIONS, REPRESENTATIONS, UNDERTAKINGS, TERMS AND OBLIGATIONS IMPLIED BY STATUTE, COMMON LAW, TRADE USAGE, COURSE OF DEALING OR OTHERWISE, ALL OF WHICH ARE HEREBY DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW.</p>

<h2>9. LIMITATION OF LIABILITY:</h2>
<p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL EITHER SECUTEL OR ITS SUBSIDIARIES AND AFFILIATES, THEIR RESPECTIVE DISTRIBUTORS AND DEALERS OR SECUTEL'S LICENSORS BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL SPECIAL OR OTHER DAMAGES WHATSOEVER (INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION OR LOSS OF BUSINESS INFORMATION), WHETHER OR NOT SECUTEL, ITS SUBSIDIARIES AND AFFILIATES, THEIR RESPECTIVE DISTRIBUTORS AND DEALERS OR SECUTEL'S LICENSORS HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY EVENT, THE ENTIRE LIABILITY OF SECUTEL, ON BEHALF OF ITSELF AND ITS SUBSIDIARIES AND AFFILIATES, THEIR RESPECTIVE DISTRIBUTORS AND DEALERS AND ANY OF SECUTEL'S LICENSORS, ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE) OR OTHERWISE SHALL BE LIMITED TO THE MA SINGLE MONTHS LICENSE FEE FOR THE RELEVANT SOFTWARE.</p>

<p>NOTHING IN THIS AGREEMENT SHALL AFFECT THE STATUTORY RIGHTS OF A CONSUMER.</p>

<h2>10. General:</h2>
<p>This Agreement constitutes the entire agreement between you and Secutel with respect to the Licensed Software and supersedes any prior oral or written representations, agreements or understandings with respect to the Licensed Software. Neither party shall have any remedy in respect of any statement made to it upon which it relied in entering into this Agreement (unless such statement was made fraudulently) and that party's only remedy shall be for breach of contract as provided for in this Agreement.</p>
<p>If at any time any part of this Agreement is found by a court of competent jurisdiction to be wholly or partly illegal, invalid or unenforceable in any respect under the law of any jurisdiction that fact shall not affect or impair the legality, validity or enforceability of any other provision of this Agreement.</p>
<p>The failure or delay of Secutel in exercising any right, power or remedy under this Agreement shall not in any circumstance operate as a waiver of such right, power or remedy.</p>
<p>No variation to this Agreement shall be effective unless in writing and signed by an authorized representative of Secutel.</p>
<p>The provisions of this Agreement, in so far as it relates to Secutel's subsidiaries and/or affiliates, are directly enforceable by such Secutel's subsidiaries and/or affiliates.</p>


