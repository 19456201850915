// @flow
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { SocketService } from '../components/socket/socket.service';
import type { SyncEvent } from '../components/socket/socket.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { forEach, head, isObject } from 'lodash';
import { AuthService } from '../components/auth/auth.service';

export type { MetaDataFE as MetaData } from 'server/api/base/base';
export interface TableHeader {
    key: string | string[];
    alias?: string;
}

export interface Model {
    _id?: string;
    [x: string]: any;
}

export interface ExportOptions {
    format: 'csv' | 'xlsx';
    headers?: TableHeader[];
    timeZone: string;
}

export interface SubQuery {
    field: string;
    value: any;
    type: 'number' | 'date' | 'string' | 'id' | 'boolean' | 'null' | 'array' | string;
    operator?: string;
}

export interface BaseQuery {
    filter?: string;
    skip?: number;
    limit?: number;
    by?: string;
    //TODO: How do you do string literal enums, without compile errors?
    order?: string;
    between?: number[];
    params?: SubQuery[];
}

export interface AggregationQuery extends BaseQuery {
    unwind?: Boolean;
    groupBy?: String | any;
    aggregation?: String | Number;
    aggregationOperator?: '$sum' | '$avg' | '$min' | '$max';
    aggregationAs?: String;
}

export class Page {
    // The number of elements in the page
    size = 0;
    // The total number of elements
    totalElements = 0;
    // The total number of pages
    totalPages = 0;
    // The current page number
    pageNumber = 0;
}

export class Sort {
    prop = '';
    dir = 'desc';
}

@Injectable()
export class BaseService<T extends Model> {
    modelName: string;

    socketService: SocketService;
    http: HttpClient;
    authService: AuthService;

    constructor(private injector: Injector) {
        //this.socketService = socketService;
        //this.http = http;
        //this.injector = injector;
        this.socketService = this.injector.get(SocketService);
        this.http = this.injector.get(HttpClient);
        setTimeout(() => {
            this.authService = this.injector.get(AuthService);
        });
    }

    /**
     * This registers
     * @param array Array that will be updated when socket events occur (NOTE : The reference to the array should not change)
     */
    register(array?: T[], options?: any, room?: string): Observable<SyncEvent> {
        const self = this;
        const modelName = self.modelName.slice(0, -1);
        return self.socketService.syncUpdates(modelName, array, room || `${modelName}:*`, options);
    }

    buildParams(query: BaseQuery): HttpParams {
        let params = new HttpParams();
        forEach(query, (value, key) => {
            if (isObject(value)) {
                params = params.set(key, JSON.stringify(value));
            } else if (typeof value.toString == 'function') {
                params = params.set(key, value.toString());
            }
        });
        return params;
    }

    // TODO: Replace Record<string, any> with query interface <20-05-20, Liaan> //
    getList(query?: BaseQuery, allAccounts = false): Observable<T[]> {
        let params: HttpParams;
        if (query) {
            params = this.buildParams(query);
        }
        let headers;
        if (allAccounts) {
            headers = new HttpHeaders({
                'x-js-all-accounts': 'true',
            });
        }
        return this.http.get<T[]>(`/api/${this.modelName}`, {
            params,
            headers,
        });
    }

    getListForGroup(group: string, assumeAccount?: string): Observable<T[]> {
        let headers: HttpHeaders;
        if (assumeAccount) {
            headers = new HttpHeaders({
                'x-js-assume-account': assumeAccount,
            });
        }
        return this.http.get<T[]>(`/api/${this.modelName}/group/${group}`, { headers });
    }

    getCount(query?: BaseQuery, allAccounts = false): Observable<number> {
        let params: HttpParams;
        if (query) {
            params = this.buildParams(query);
        }
        let headers;
        if (allAccounts) {
            headers = new HttpHeaders({
                'x-js-all-accounts': 'true',
            });
        }
        return this.http.get<number>(`/api/${this.modelName}/count`, {
            params,
            headers,
        });
    }

    getById(id: string): Observable<T> {
        return this.http.get<T>(`/api/${this.modelName}/${id}`);
    }

    save(modelData: T): Observable<T> {
        if (modelData._id) {
            return this.http.patch<T>(`/api/${this.modelName}/${modelData._id}`, modelData);
        } else {
            return this.http.post<T>(`/api/${this.modelName}`, modelData);
        }
    }

    remove(id: string): Observable<T> {
        return this.http.delete<T>(`/api/${this.modelName}/${id}`);
    }

    exportData(options: ExportOptions, query?: BaseQuery, allAccounts = false): Observable<Blob> {
        let params: HttpParams;
        if (query) {
            params = this.buildParams(query);
        }
        let contentType = 'application/json';
        switch (options.format) {
            case 'csv':
                contentType = 'text/csv';
                break;
            case 'xlsx':
                contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;

            default:
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: contentType,
        });

        if (allAccounts) {
            headers = headers.append('x-js-all-accounts', 'true');
        }

        return this.http.post<Blob>(`/api/${this.modelName}/export`, options, {
            params,
            headers,
            responseType: 'blob' as 'json',
        });
    }

    // TODO: Replace Record<string, any> with query interface <20-05-20, Liaan> //
    aggregate(query?: AggregationQuery, allAccounts = false): Observable<any[]> {
        let params: HttpParams;
        if (query) {
            params = this.buildParams(query);
        }
        let headers;
        if (allAccounts) {
            headers = new HttpHeaders({
                'x-js-all-accounts': 'true',
            });
        }
        return this.http.get<T[]>(`/api/${this.modelName}/aggregate`, {
            params: params,
            headers: headers,
        });
    }
}
