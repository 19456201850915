import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConsentModal } from './consent.modal.component';
import { Eula } from './eula.component';
import { Privacy } from './privacy.component';
import { Disclaimer } from './disclaimer.component';
import { Terms } from './terms.component';

@NgModule({
    declarations: [ConsentModal, Eula, Privacy, Disclaimer, Terms],
    imports: [CommonModule, NgbModule],
    exports: [ConsentModal],
    entryComponents: [ConsentModal],
})
export class ConsentModule {}
