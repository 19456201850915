/* ----------------------------- Angular Imports ---------------------------- */
import { formatDate } from '@angular/common';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';

/* ----------------------------- Module Imports ----------------------------- */
import { lastValueFrom } from 'rxjs';

/* ----------------------------- Service Imports ---------------------------- */
import { BaseService } from './base.service';

/* ------------------------------ Type Imports ------------------------------ */
import type { BaseQuery } from './base.service';
import type { Event } from './event.service';

@Injectable()
export class ReportService extends BaseService<Event> {
    /* -------------------------------------------------------------------------- */
    /*                                 Properties                                 */
    /* -------------------------------------------------------------------------- */
    modelName = 'reports';

    /* -------------------------------------------------------------------------- */
    /*                                 Constructor                                */
    /* -------------------------------------------------------------------------- */
    constructor(
        injector: Injector,
        @Inject(LOCALE_ID) public locale: string
    ) {
        super(injector);
    }

    /* -------------------------------------------------------------------------- */
    /*                                GET Functions                               */
    /* -------------------------------------------------------------------------- */
    public downloadIncidentReport(query?: BaseQuery, allAccounts = false) {
        let params: HttpParams;
        if (query) params = this.buildParams(query);

        let headers: HttpHeaders;
        if (allAccounts) headers = new HttpHeaders({ 'x-js-all-accounts': 'true' });

        return lastValueFrom(
            this.http.get('/api/reports/incident', { headers, params, responseType: 'arraybuffer' })
        ).then((data: Buffer) => {
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = fileURL;
            a.target = '_blank';
            a.download = `NoKey Incident Report - ${this.authService.currentAccount.name} - ${formatDate(new Date(), 'dd-mm-yyyy', this.locale)}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    }
}
